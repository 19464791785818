<script setup>
const {onWindowEvent} = useWindowEvents();

const emits = defineEmits(['resize']);

const leftValue = defineModel('leftValue');
const rightValue = defineModel('rightValue');

const refTrack = ref();
const trackWidth = ref(0);
const trackX = ref(0);

const calculateTrackWidth = () => {
  if(!refTrack.value) return;
  const {width, x} = refTrack.value.getBoundingClientRect();
  trackWidth.value = width;
  trackX.value = x;
  emits('resize', width, x)
}

onWindowEvent('resize', () => {
  nextTick(() => calculateTrackWidth())
});
onMounted(() => calculateTrackWidth());

defineExpose({
  trackWidth,
  trackX
})

</script>

<template>
  <div class="w-full h-10" ref="refTrack">
    <div class="h-0.5 mt-5 bg-gray-200 w-full relative">
      <div class="absolute h-0.5 bg-azure" :style="{left: leftValue, width: parseInt(rightValue) - parseInt(leftValue) + 'px'}"></div>
    </div>
  </div>
</template>
