<script setup>

</script>

<template>
  <div class="absolute top-0 w-10 h-10 rounded-full shadow-box bg-white flex items-center justify-center cursor-pointer">
    <svg width="22" height="13" viewBox="0 0 22 13" fill="none" xmlns="http://www.w3.org/2000/svg" class="pointer-events-none">
      <path d="M6 11.5L1 6.5L6 1.5" stroke="#0A66A8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M16 1.5L21 6.5L16 11.5" stroke="#0A66A8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  </div>
</template>
